import { workflowStepIds } from './session'

export const salesforcePath = '/salesforce'
export const unauthorizedPath = '/unauthorized'
export const closedLostPath = '/closedLost'

export const opportunityStages = {
  closedLost: 'Closed Lost'
}

export const sections = {
  orders: {
    title: 'Orders',
    id: 'orders',
    icon: 'form',
    path: '/orders'
  },
  settings: {
    title: 'Settings',
    id: 'settings',
    icon: 'team',
    path: '/settings'
  }
}

export const orderRouteIds = {
  dashboard: 'dashboard',
  offers: 'offers',
  configure: 'configure',
  documents: 'documents',
  submit: 'submit',
  success: 'success',
  closedLost: 'closedLost'
}

export const orderRoutes = {
  dashboard: {
    id: orderRouteIds.dashboard,
    index: 0,
    section: 'orders',
    path: `/${orderRouteIds.dashboard}`,
    title: 'Order Dashboard',
    disabled: true,
    completed: false,
    isLandingPage: true
  },
  offers: {
    id: orderRouteIds.offers,
    index: 1,
    section: 'orders',
    path: `/${orderRouteIds.offers}`,
    title: 'Present Offers',
    disabled: true,
    completed: false
  },
  configure: {
    id: orderRouteIds.configure,
    index: 2,
    section: 'orders',
    path: `/${orderRouteIds.configure}`,
    title: 'Configure',
    disabled: true,
    completed: false
  },
  documents: {
    id: orderRouteIds.documents,
    index: 3,
    section: 'orders',
    path: `/${orderRouteIds.documents}`,
    title: 'Documents',
    disabled: true,
    completed: false
  },
  submit: {
    id: orderRouteIds.submit,
    index: 4,
    section: 'orders',
    path: `/${orderRouteIds.submit}`,
    title: 'Install / Order Submit',
    disabled: true,
    completed: false
  },
  success: {
    id: orderRouteIds.success,
    index: 5,
    section: 'orders',
    path: `/${orderRouteIds.success}`,
    title: 'Success',
    disabled: true,
    completed: false,
    resubmitDisabled: false,
    isLandingPage: true,
    hideNavigator: true
  },
  closedLost: {
    id: orderRouteIds.closedLost,
    index: 6,
    section: 'orders',
    path: `/${orderRouteIds.closedLost}`,
    title: 'Closed Lost',
    disabled: true,
    completed: false,
    resubmitDisabled: false,
    isLandingPage: true,
    hideNavigator: true
  }
}

// TODO: Remove when workflow API is updated in all envs
// Currently used for backwards compatibility
export const defaultOrderStatusRoutes = {
  0: {
    defaultRoute: orderRoutes.offers
  },
  1: {
    defaultRoute: orderRoutes.offers
  },
  2: {
    defaultRoute: orderRoutes.configure
  },
  3: {
    defaultRoute: orderRoutes.dashboard,
    continueOrderRoute: orderRoutes.documents
  },
  4: {
    defaultRoute: orderRoutes.dashboard,
    continueOrderRoute: orderRoutes.submit
  },
  5: {
    defaultRoute: orderRoutes.dashboard,
    continueOrderRoute: orderRoutes.success
  },
  6: {
    defaultRoute: orderRoutes.dashboard,
    continueOrderRoute: orderRoutes.submit
  },
  7: {
    defaultRoute: orderRoutes.dashboard,
    continueOrderRoute: orderRoutes.success
  }
}

export const defaultOrderRoute = {
  workflowStepId: workflowStepIds.NOT_STARTED,
  defaultRoute: orderRoutes.offers
}

export const settingsRoutes = {
  featToggleList: {
    id: 'featToggleList',
    section: 'settings',
    exact: true,
    path: '/feature-toggles',
    title: 'Feature Toggles'
  },
  featToggleDetails: {
    id: 'featToggleDetails',
    section: 'settings',
    path: '/feature-toggles/:id',
    title: 'Feature Toggle'
  }
}

export const routes = {
  ...orderRoutes,
  ...settingsRoutes
}

export const bbToggleKey = 'f6494b9e-b375-401b-9a02-58bf4962dadd'

export const bundleBuilderRedirectUrls = {
  local:
    'https://comcast--sfdce2e1--c.cs93.visual.force.com:443/apex/SMBOpportunityConfigureOffer?id=',
  test:
    'https://comcast--sfdce2e1--c.cs93.visual.force.com:443/apex/SMBOpportunityConfigureOffer?id=', // for unit tests
  dev2:
    'https://comcast--sfdcdev3--c.cs47.visual.force.com/apex/SMBOpportunityConfigureOffer?id=',
  qa2:
    'https://comcast--sfdce2e1--c.cs93.visual.force.com:443/apex/SMBOpportunityConfigureOffer?id=',
  qa:
    'https://comcast--sfdce2e2--c.cs47.visual.force.com/apex/SMBOpportunityConfigureOffer?id=',
  brt:
    'https://comcast--sfdcstg--c.cs96.visual.force.com/apex/SMBOpportunityConfigureOffer?id=',
  brt2:
    'https://comcast--sfdcbrtfl--c.visualforce.com/apex/SMBOpportunityConfigureOffer?id=',
  g: 'https://comcast--c.visualforce.com/apex/SMBOpportunityConfigureOffer?id='
}
